import { FC } from 'react';
import Button, { ButtonType } from '../shared/form-control/Button';

type PublicFormFooterProps = {
  buttonText: string;
  disabled?: boolean;
  onNext: () => void;
  submitting?: boolean;
};

const PublicFormFooter: FC<PublicFormFooterProps> = (props) => {
  const { buttonText, disabled, onNext, submitting } = props;

  return (
    <div className="pb-8 pt-6">
      <Button
        type={ButtonType.PRIMARY}
        onClick={onNext}
        disabled={disabled}
        loading={submitting}
        className="w-full hover:!scale-[1.01] hover:shadow-lg focus:!scale-[1.01]"
        containerClassName="w-full"
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default PublicFormFooter;
