import { FC } from 'react';
import { Heading, HeadingSize } from '../shared/text/Heading';
import PublicFormBox from './PublicFormBox';
import logo from '../../assets/images/playbook-small.svg';
import { useTranslation } from 'react-i18next';

type PublicFormHeaderProps = {
  title: string;
  description: string;
};

const PublicFormHeader: FC<PublicFormHeaderProps> = (props) => {
  const { title, description } = props;
  const { t } = useTranslation('common');

  return (
    <>
      <div className="-mb-4 mt-4 flex items-center justify-end gap-2 pr-1">
        <span className="text-dpm-12">{t('powered-by')}</span>
        <img src={logo} alt="Playbook" className="h-[18px]" />
      </div>
      <PublicFormBox className="linear-border-rainbow-gradient">
        <Heading size={HeadingSize.H1} actualSize={HeadingSize.H4} className="font-medium" textColor="text-black">
          {title}
        </Heading>
        <div className="text-gray-2">{description}</div>
      </PublicFormBox>
    </>
  );
};

export default PublicFormHeader;
