import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FormRendererMode } from '../../contexts/FormRendererDesignContextTypes';
import { FormRendererDesignContext, FormRendererDesignDefaults } from '../../contexts/FormRendererDesignContext';
import { FormActionWrapperProps, FormStepTitleProviderProps, FormStepWrapperProps } from '../../contexts/FormRendererDesignContextTypes';
import { EventSystem } from '../../events/EventSystem';
import { FormValidEvent } from '../../events/FormValidEvent';
import { FormConfig } from '../../models/Form';
import ClientPublicFormService from '../../services/ClientPublicFormService';
import LanguageUtils from '../../utils/LanguageUtils';
import FormRendererV2 from '../form/renderer/FormRendererV2';
import { Heading, HeadingSize } from '../shared/text/Heading';
import PublicFormBox from './PublicFormBox';
import PublicFormFooter from './PublicFormFooter';
import { FCWithChildren } from '../../types/FCWithChildren';
import { FormRendererType, useFormRendererInfo } from '../../contexts/FormRendererContext';
import { FormType } from '../../models/FormTypes';

export const PublicFormSectionWrapper: FCWithChildren<FormStepWrapperProps> = (props) => {
  const { step, children } = props;
  const { config } = useFormRendererInfo();

  if (!step.visible) {
    return null;
  }

  return <PublicFormBox zIndex={config.sections.findIndex((x) => x.id === step.id) + 1}>{children}</PublicFormBox>;
};

export const PublicFormSectionTitle: FC<FormStepTitleProviderProps> = (props) => {
  const { step } = props;
  const { config } = useFormRendererInfo();

  return (
    <Heading textColor="text-dpm-gray-1 mb-4" size={HeadingSize.H5} className="px-4 font-medium">
      {config.type === FormType.Document ? step.number : ''} {LanguageUtils.getTranslation('title', step.translations)}
    </Heading>
  );
};

export const PublicFormActionWrapper: FCWithChildren<FormActionWrapperProps> = (props) => {
  const { isLast, children } = props;

  return <div className={`${isLast ? '' : 'mb-4'}`}>{children}</div>;
};

type PublicFormViewProps = {
  form: FormConfig | null;
  setRequestCode: (value: string) => void;
  goNext: () => void;
};

const PublicFormView: FC<PublicFormViewProps> = (props) => {
  const { form, setRequestCode, goNext } = props;
  const [formValid, setFormValid] = useState(false);
  const { t } = useTranslation('public-forms');
  const { publicFormId } = useParams<{ publicFormId: string }>();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const validHander = (e: FormValidEvent) => setFormValid(e.valid);

    EventSystem.listen('form-valid', validHander);

    return () => {
      EventSystem.stopListening('form-valid', validHander);
    };
  }, []);

  const submitForm = useCallback(() => {
    setSubmitting(true);
    ClientPublicFormService.submit(publicFormId as string).then((res) => {
      setRequestCode(res.data);
      goNext();
    });
  }, [goNext, publicFormId, setRequestCode]);

  return form ? (
    <>
      <FormRendererDesignContext.Provider
        value={{
          ...FormRendererDesignDefaults,
          sectionWrapper: PublicFormSectionWrapper,
          actionWrapper: PublicFormActionWrapper,
          sectionTitleProvider: PublicFormSectionTitle,
        }}
      >
        <FormRendererV2
          form={form}
          initialMode={FormRendererMode.EditView}
          renderAllSteps
          featureToggles={{ disableModeToggle: true }}
          canEdit
          type={FormRendererType.Public}
        />
      </FormRendererDesignContext.Provider>
      <PublicFormFooter onNext={submitForm} disabled={!formValid} buttonText={t('footer.submit')} submitting={submitting} />
    </>
  ) : null;
};

export default PublicFormView;
