import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, HeadingSize } from '../shared/text/Heading';
import PublicFormBox from './PublicFormBox';

const PublicFormNotFound: FC = () => {
  const { t } = useTranslation('public-forms');

  return (
    <div className="flex h-full items-center justify-center">
      <PublicFormBox className="text-center">
        <Heading size={HeadingSize.H2} className="font-medium">
          {t('not-found.title')}
        </Heading>
        <div>{t('not-found.description')}</div>
      </PublicFormBox>
    </div>
  );
};

export default PublicFormNotFound;
